var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("default"),_c('div',{staticClass:"create-section-text mb-2 mt-3"},[_vm._v(" Select a Pricing Method for this lane section ")]),_c('div',{staticClass:"field-dropdown-main mb-4 price-method-dropdwon"},[_c('choices-single',{attrs:{"id":_vm.label == 'Origin'
          ? 'editLanePriceMethod'
          : 'editLaneDestinationPriceMethod',"options":_vm.priceMethodList},on:{"input":event => _vm.handleSelectPriceMethod(event)},model:{value:(_vm.localSelectedPriceMethod),callback:function ($$v) {_vm.localSelectedPriceMethod=$$v},expression:"localSelectedPriceMethod"}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Select")])]),(_vm.priceMethodInvalid)?_c('span',{staticClass:"validate-error"},[_vm._v(" The Price method is required ")]):_vm._e()],1),_c('currency-selection',{attrs:{"currency":_vm.localCurrency},on:{"currency-default-changed":_vm.handleCurrencyChangeCrossBorder,"update:currency":function($event){_vm.localCurrency=$event}}}),(
      _vm.selectedLanePricingMethods && _vm.selectedLanePricingMethods.key == 'fixed'
    )?_c('div',{staticClass:"fixed-input mb-4"},[_c('span',{staticClass:"data-type-input left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localDollarValue),expression:"localDollarValue"}],staticClass:"form-control",attrs:{"type":"number","step":"any","placeholder":"00.00"},domProps:{"value":(_vm.localDollarValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localDollarValue=$event.target.value},event => _vm.handleChangeValue(event)],"blur":_vm.handleAddDollarDecimal}})]),(_vm.dollarValueInvalid)?_c('span',{staticClass:"validate-error"},[_vm._v("The Price field is required")]):_vm._e()]):_vm._e(),(
      _vm.selectedLanePricingMethods && _vm.selectedLanePricingMethods.key != 'fixed'
    )?_c('div',[_c('lane-pricing-not-fixed',{attrs:{"unitPriceList":_vm.unitPriceList,"selectedLanePricingMethods":_vm.selectedLanePricingMethods,"currencySymbol":_vm.currencySymbol}}),(_vm.selectedLanePricingMethods.allowMultipleValues)?_c('div',{staticClass:"mt-3"},[_c('base-button',{on:{"click":_vm.handleAddUnitPrice}},[_c('img',{attrs:{"src":require("../../assets/img/plus-active.svg")}}),_vm._v(" Add ")])],1):_vm._e()],1):_vm._e(),(
      _vm.selectedLanePricingMethods && _vm.selectedLanePricingMethods.key == 'class'
    )?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{staticClass:"create-section-text mb-2"},[_vm._v(" Choose a Base Rate ")]),_c('div',{staticClass:"mb-4 price-method-dropdwon"},[_c('choices-single',{attrs:{"options":_vm.baseUnitsListing},on:{"input":event => _vm.handleSelectBaseUnits(event)},model:{value:(_vm.localSelectedBaseUnits),callback:function ($$v) {_vm.localSelectedBaseUnits=$$v},expression:"localSelectedBaseUnits"}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Select Base rate")])])],1)]),_vm._m(0)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('lane-pricing-class',{attrs:{"selectedBaseUnit":_vm.localSelectedBaseUnits,"selectedClassUnits":_vm.localSelectedClassUnits,"isViewClassBreaks":_vm.localIsViewClassBreaks,"classRateInvalid":_vm.classRateInvalid,"currencySymbol":_vm.currencySymbol},on:{"update:isViewClassBreaks":function($event){_vm.localIsViewClassBreaks = $event},"select-class-units":_vm.handleSelectClassUnits}})],1)])]):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-4 mt-lg-4"},[_c('button',{staticClass:"btn btn-default mb-4 mb-lg-0 mt-lg-2",attrs:{"type":"button"}},[_vm._v(" Authenticate ")])])
}]

export { render, staticRenderFns }