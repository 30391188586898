<template>
    <div class="d-flex justify-content-end mt-3 mt-md-1 mt-lg-0 toggle-container">
        <div class="d-flex toggle-switch">
            <button
                type="button" 
                class="btn switch-slider px-3 pt-1" 
                :class="{ 'btn-primary': tariffType === 'private' }"
                style="margin-right: -3px;"
                @click="updateMode('private')"
            >
                Private
            </button>
            <button
                type="button" 
                class="btn switch-slider px-3 pt-1" 
                :class="{ 'btn-primary': tariffType === 'public' }" 
                @click="updateMode('public')"
            >
                Public
            </button>
        </div>
    </div>
</template>

<script>
export default {
    bodyClass: "landing",
    props: {
     value: String,
    },
    computed: {
        tariffType() {
            return this.value; // Use the 'value' prop
        }
    },
    methods: {
        updateMode(mode) {
            this.$emit('input', mode); // Emit the new mode to the parent
        }
    }
}
</script>

<style></style>
