import Vue from "vue";
import Router from "vue-router";
import Register from "./views/Register.vue";
import Reset from "./views/Reset.vue";
import SignInHeader from "./layout/SignInHeader";
import AppFooter from "./layout/AppFooter";
import Home from "./views/Home.vue";
import CreateCustomFields from "./views/CreateCustomFields.vue";
import ViewAllSection from "./views/ViewAllSection.vue";
import CreateTariff from "./views/tariff/CreateTariff.vue";
import EditTariff from "./views/tariff/EditTariff.vue";
import EditRouting from "./views/routing/EditRouting.vue";
import DuplicateTariff from "./views/tariff/DuplicateTariff.vue";
import ViewAllTariff from "./views/tariff/ViewAllTariff.vue";
import ViewAllQuotes from "./views/quotes/ViewAllQuotes.vue";
import EditDuplicateLane from "./views/lane/EditDuplicateLane.vue";
import EditDuplicateAccessorial from "./views/accessorial/EditDuplicateAccessorial.vue";
import EditDuplicateFuelSurcharge from "./views/accessorial/EditDuplicateFuelSurcharge.vue";
import EditDuplicateCondition from "./views/condition/EditDuplicateCondition.vue";
import EditClassBaseRate from "./views/baserate/EditClassBaseRate.vue";
import EditDuplicateCustomer from "./views/customers/EditDuplicateCustomer.vue";
import SelectTenant from "./views/tenant/SelectTenant.vue";
import NewTenant from "./views/tenant/NewTenant.vue";
import store from "/src/store";
import { brokerRestrictedPaths } from "@/helpers/utility";
//import AppHeader from "@/layout/AppHeader.vue";

Vue.use(Router);

export const routesList = [
  {
    path: "/",
    name: "register",
    components: {
      default: Register
    }
  },
  {
    path: "/reset",
    name: "reset",
    components: {
      header: SignInHeader,
      default: Reset,
      footer: AppFooter
    }
  },
  {
    path: "/",
    name: "home",
    components: {
      //header: AppHeader,
      default: Home
    }
  },
  {
    path: "/create-custom-fields",
    name: "create-custom-fields",
    components: {
      default: CreateCustomFields
    }
  },
  {
    path: "/viewallsection",
    name: "viewallsection",
    components: {
      default: ViewAllSection
    }
  },
  {
    path: "/create-tariff",
    name: "create-tariff",
    components: {
      default: CreateTariff
    }
  },
  {
    path: "/tariff/edit/:id",
    name: "editTariff",
    components: {
      default: EditTariff
    }
  },
  {
    path: "/tariff/duplicate/:id",
    name: "duplicateTariff",
    components: {
      default: DuplicateTariff
    }
  },
  {
    path: "/view-all-tariff",
    name: "view-all-tariff",
    components: {
      default: ViewAllTariff
    }
  },
  {
    path: "/view-all-quotes",
    name: "view-all-quotes",
    components: {
      default: ViewAllQuotes
    }
  },
  {
    path: "/lane/:view/:id",
    name: "editLane",
    components: {
      default: EditDuplicateLane
    }
  },
  {
    path: "/accessorial/:view/:id",
    name: "editAccessorial",
    components: {
      default: EditDuplicateAccessorial
    }
  },
  {
    path: "/fuel-surcharge/:view/:id",
    name: "editFuelSurcharge",
    components: {
      default: EditDuplicateFuelSurcharge
    }
  },
  {
    path: "/condition/:view/:id",
    name: "editCondition",
    components: {
      default: EditDuplicateCondition
    }
  },
  {
    path: "/baserate/:view/:id",
    name: "editBaserate",
    components: {
      default: EditClassBaseRate
    }
  },
  {
    path: "/customer/:view/:id",
    name: "editCustomer",
    components: {
      default: EditDuplicateCustomer
    }
  },
  {
    path: "/tenant",
    name: "tenant",
    components: {
      default: SelectTenant
    }
  },
  {
    path: "/tenant/new",
    name: "newTenant",
    components: {
      default: NewTenant
    }
  },
  {
    path: "/routing/:view/:id",
    name: "editRouting",
    components: {
      default: EditRouting
    }
  }
];

const router = new Router({
  routes: routesList,
  linkActiveClass: "active",
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const session = localStorage.getItem("id_token");
  next();
  if (session != null && session !== "undefined" && session !== undefined) {
    // Dispatch an action to fetch profile and account data from the store
    store.dispatch("getProfileData");
    if (localStorage.getItem("accountId")) {
      store.dispatch("getAccountData");
    }

    // Get the userIsBroker flag from the Vuex store
    const userIsBroker = store.getters["userIsBroker"];

    // Check if the current path is restricted for brokers
    const isRestrictedPath = brokerRestrictedPaths?.some(path => {
      const regex = new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`);
      return regex.test(to.path);
    });

    // If the user is a broker and the path is restricted, redirect to /viewallsection
    if ((userIsBroker && isRestrictedPath) || to.path === "/") {
      router
        .push({ path: "/viewallsection", name: "viewallsection" })
        .catch(() => {});
    } else {
      router.push({ path: to.path, name: to.name }).catch(() => {});
    }
  } else {
    // If the session is not valid and the user is navigating to /reset, allow the navigation
    if (to.path === "/reset") {
      router.push({ path: to.path, name: to.name }).catch(() => {});
    } else {
      router.push({ path: "/", name: "register" }).catch(() => {});
    }
  }
});

export default router;
