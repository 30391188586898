<template>
  <div class="d-flex align-items-center">    
    <!-- Checkbox -->
    <div class="custom-control custom-checkbox">
      <input
        :id="item.id"
        type="checkbox"
        v-model="localSelect"
        class="custom-control-input single-checkbox"
        @change="emitRowSelection"
        :class="{
          'fuelSurcharge-checkbox': listName === 'Fuel Surcharge',
          [`${listName}-checkbox`]: listName !== 'Fuel Surcharge'
        }"
      />
      <label :for="item.id" class="custom-control-label"></label>
    </div>
       
    <!-- Input -->
    <div>
      <vue-input
        class="mb-0"
        alternative
        placeholder="Value"
        type="number" 
        v-model="localTariffValue"
        @input="emitTariffValue"
      >
      </vue-input>
    </div>  

    <!-- Switch for Pricing Mode -->
    <div class="d-flex justify-content-end mt-3 mt-md-1 mt-lg-0 ml-2">
      <div class="d-flex toggle-switch">
        <button
          type="button" 
          class="btn switch-slider px-2 pt-1 price-symbol-switch" 
          :class="{ 'btn-primary': localPriceMode === 'percentage' }"
          style="margin-right: -3px;"
          @click="emitUpdatePriceMode('percentage')"
        >
          <span> % </span>
        </button>
        <button
          type="button" 
          class="btn switch-slider px-2 pt-1 price-symbol-switch"
          :class="{ 'btn-primary': localPriceMode === 'dollar' }" 
          @click="emitUpdatePriceMode('dollar')"
        >
          <span> $ </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { 
    getPercentageRestrictedTypes, 
    allItemsHavePercentagePricing, 
    priceMethodMode, 
    dataType,
  } from "@/helpers/utility";

  export default {
    name: "EditingColumnTariff",
    props: {
      item: {
        type: Object,
        required: true,
      },
      selectAll: {
        type: Boolean,
        default: false,
      },
      tariffValue: [Number, String],
      listName: {
        type: String
      },
    },
    data() {
      return {
        localPriceMode: this.item.priceMode || priceMethodMode.dollar, // Local copy of the prop
        localSelect: this.item.checked || false,
        localTariffValue: this.item.tariffValue || this.tariffValue,
      }
    },
    watch: {
      selectAll(newValue) {
        this.localSelect = newValue;
        this.emitRowSelection();
      },
      tariffValue(newValue) {
        this.localTariffValue = newValue;
      },
      item(newvalue) {
        this.localPriceMode = newvalue.priceMode;
      }
    },
    methods: {
      /**
       * Emits an event to notify the parent component of the selected row.
       * @param {Object} item - The item that was selected.
       */
      emitRowSelection() {
        this.$emit("row-selected", { ...this.item, checked: this.localSelect });
      },
      /**
       * Emits the updated price mode to the parent component.
       * @param {string} mode - The new price mode.
       */
      emitUpdatePriceMode(mode) {
        this.localPriceMode = mode; 
        this.$emit("update-price-mode", this.item.id, mode, this.listName); // Emit the updated mode

        this.$nextTick(() => {
          this.localPriceMode = this.item.priceMode; // Sync with parent
        });
      },
      /**
       * Emits the updated tariff value for a specific row to the parent component.
       * @param {string|number} id - The ID of the item being updated.
       * @param {number} value - The updated tariff value.
       */
      emitTariffValue() {
        this.$emit("update-tariff-value", {
          id: this.item.id,
          value: this.localTariffValue,
        });
      },
      /**
       * Sets the default price mode based on the `dataTypeFields` of the item.
       */
       setDefaultPriceMode() {
        if (this.item && this.item.dataTypeFields) {
          const dataTypeField = this.item.dataTypeFields[0]; // Assuming first entry is the relevant one
          const restrictedTypes = getPercentageRestrictedTypes(); // Get restricted types from the utility

          // Check if the type matches any of the restricted types and set the price mode accordingly
          if (restrictedTypes.includes(dataTypeField.type)) {
            if (dataTypeField.type === dataType.weightRange) {
              // Check if all items have the "Percentage" pricing method
              this.localPriceMode = allItemsHavePercentagePricing(this.item) ? priceMethodMode.percentage : priceMethodMode.dollar;
            } else {
              // Default to "percentage" for other restricted types
              this.localPriceMode = priceMethodMode.percentage;
            }
          } else {
            // Default to "dollar" for non-restricted types
            this.localPriceMode = priceMethodMode.dollar;
          }
        }
      }
    },
    mounted() {
      // Set default price mode based on accessorial type
      this.setDefaultPriceMode();
    },
  };
</script>

<style scoped>
  .price-symbol-switch {
    height: 22px !important;
    display: flex !important;
    align-items: center !important;
  }
</style>
