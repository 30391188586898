<template>
  <div>
    <slot />
    <div class="create-section-text mb-2 mt-3">
      Select a Pricing Method for this lane section
    </div>
    <div class="field-dropdown-main mb-4 price-method-dropdwon">
      <choices-single
        :id="
          label == 'Origin'
            ? 'editLanePriceMethod'
            : 'editLaneDestinationPriceMethod'
        "
        :options="priceMethodList"
        v-model="localSelectedPriceMethod"
        @input="event => handleSelectPriceMethod(event)"
      >
        <option value="0">Select</option>
      </choices-single>
      <span v-if="priceMethodInvalid" class="validate-error">
        The Price method is required
      </span>
    </div>

    <!-- Currency type dropdown starts-->
    <currency-selection
      @currency-default-changed="handleCurrencyChangeCrossBorder"
      :currency.sync="localCurrency"
    />

    <div
      class="fixed-input mb-4"
      v-if="
        selectedLanePricingMethods && selectedLanePricingMethods.key == 'fixed'
      "
    >
      <span class="data-type-input left">
        <input
          type="number"
          step="any"
          class="form-control"
          placeholder="00.00"
          v-model="localDollarValue"
          @input="event => handleChangeValue(event)"
          @blur="handleAddDollarDecimal"
        />
      </span>
      <span v-if="dollarValueInvalid" class="validate-error"
        >The Price field is required</span
      >
    </div>

    <div
      v-if="
        selectedLanePricingMethods && selectedLanePricingMethods.key != 'fixed'
      "
    >
      <lane-pricing-not-fixed
        :unitPriceList="unitPriceList"
        :selectedLanePricingMethods="selectedLanePricingMethods"
        :currencySymbol="currencySymbol"
      />
      <div class="mt-3" v-if="selectedLanePricingMethods.allowMultipleValues">
        <base-button @click="handleAddUnitPrice">
          <img src="../../assets/img/plus-active.svg" />
          Add
        </base-button>
      </div>
    </div>

    <!-- For base rate -->
    <div
      v-if="
        selectedLanePricingMethods && selectedLanePricingMethods.key == 'class'
      "
    >
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="create-section-text mb-2">
            Choose a Base Rate
          </div>

          <div class=" mb-4 price-method-dropdwon">
            <choices-single
              :options="baseUnitsListing"
              v-model="localSelectedBaseUnits"
              @input="event => handleSelectBaseUnits(event)"
            >
              <option value="0">Select Base rate</option>
            </choices-single>
          </div>
        </div>

        <div class="col-12 col-lg-4 mt-lg-4">
          <button type="button" class="btn btn-default mb-4 mb-lg-0 mt-lg-2">
            Authenticate
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <lane-pricing-class
            :selectedBaseUnit="localSelectedBaseUnits"
            :selectedClassUnits="localSelectedClassUnits"
            :isViewClassBreaks="localIsViewClassBreaks"
            @update:isViewClassBreaks="localIsViewClassBreaks = $event"
            @select-class-units="handleSelectClassUnits"
            :classRateInvalid="classRateInvalid"
            :currencySymbol="currencySymbol"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ChoicesSingle from "@/components/SingleSelect";
  import CurrencySelection from "./CurrencySelection.vue";
  import LanePricingNotFixed from "./LanePricingNotFixed.vue";
  import LanePricingClass from "./LanePricingClass.vue";
  import { getCurrencyType } from "@/helpers/utility";
  export default {
    bodyClass: "landing",
    components: {
      ChoicesSingle,
      CurrencySelection,
      LanePricingNotFixed,
      LanePricingClass
    },
    props: {
      label: {
        type: String
      },
      priceMethodList: {
        type: Array
      },
      selectedPriceMethod: {
        type: String
      },
      selectedLanePricingMethods: {
        type: Object
      },
      unitPriceList: {
        type: Array
      },
      selectedBaseUnit: {
        type: String
      },
      selectedClassUnits: {
        type: String
      },
      isViewClassBreaks: {
        type: Boolean
      },
      classRateInvalid: {
        type: Boolean
      },
      baseUnitsListing: {
        type: Array
      },
      dollarValue: {
        type: String
      },
      dollarValueInvalid: {
        type: Boolean
      },
      priceMethodInvalid: {
        type: Boolean
      },
      currency: {
        type: String
      }
    },
    data: function() {
      return {
        country: ["us", "ca"],
        localSelectedPriceMethod: this.selectedPriceMethod,
        localSelectedBaseUnits: this.selectedBaseUnit,
        localSelectedClassUnits: this.selectedClassUnits,
        localIsViewClassBreaks: this.isViewClassBreaks,
        localDollarValue: this.dollarValue,
        currencyDefault: "USD",
        localCurrency: this.currency // Create local copy of the currency prop
      };
    },
    watch: {
      selectedPriceMethod(newValue) {
        this.localSelectedPriceMethod = newValue; // Sync with prop when it changes
      },
      selectedBaseUnit(newVal) {
        this.localSelectedBaseUnits = newVal;
      },
      selectedClassUnits(newVal) {
        this.localSelectedClassUnits = newVal;
      },
      isViewClassBreaks(newVal) {
        this.localIsViewClassBreaks = newVal;
      },
      dollarValue(newVal) {
        this.localDollarValue = newVal;
      },
      // Watch for changes in the prop and update localCurrency accordingly
      currency(newValue) {
        this.localCurrency = newValue;
      },
      localCurrency(newValue) {
        this.$emit("update:currency", newValue);
      }
    },
    computed: {
      currencySymbol() {
        const currencyType = getCurrencyType(this.currency);
        return currencyType;
      }
    },
    methods: {
      /**
       * Handle the selection of a price method
       * @param {Event} e - The event object
       */
      handleSelectPriceMethod(e) {
        if (this.label === "Origin") {
          this.$emit("select-price-method", e);
          this.$emit("update:priceMethodInvalid", false);
        } else if (this.label === "Destination") {
          this.$emit("select-destination-price-method", e);
          this.$emit("update:priceMethodInvalid", false);
        }
      },
      /**
       * Updates the currency default based on the new value
       * @param {String} newValue - The new currency value
       */
      handleCurrencyChangeCrossBorder(newValue) {
        this.currencyDefault = newValue;
      },
      /**
       * Emits an event to the parent to add a unit price
       */
      handleAddUnitPrice() {
        this.$emit("add-unit-price");
      },
      /**
       * Emits the selected class units to the parent
       * @param {Event} e - The event object containing the selected value
       */
      handleSelectClassUnits(e) {
        this.$emit("select-class-units", e);
      },
      /**
       * Emits the selected base units to the parent and hides the class breaks view
       */
      handleSelectBaseUnits() {
        this.$emit("select-base-units", this.localSelectedBaseUnits);
        this.localIsViewClassBreaks = false;
      },
      /**
       * Emits the updated dollar value to the parent
       */
      handleChangeValue() {
        this.$emit("change-value", this.localDollarValue);
        this.$emit("update:dollarValueInvalid", false);
      },
      /**
       * Emits an event to the parent to add the decimal points
       */
      handleAddDollarDecimal() {
        this.$emit("add-dollar-decimal");
      }
    }
  };
</script>
