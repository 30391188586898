<template>
  <div>
    <v-app>
      <v-data-table :headers="headers" :items="tableData" class="elevation-1">
        <template v-slot:item.subCities="{ item }">
          <subcities-dropdown :item="item" />
        </template>
        <template v-slot:item.action="{ item }">
          <div class="d-flex">
            <base-button
              type="primary"
              class="btn-theme-EFEBEB left-border btn-br1"
              @click="editDuplicateView(item.id, true)"
            >
              <img src="../../assets/img/edit.png" />
            </base-button>
            <base-button
              type="primary"
              class="btn-theme-EFEBEB right-border btn-br1"
              @click="editDuplicateView(item.id, false)"
            >
              <img src="../../assets/img/duplicate.png" />
            </base-button>
            <!-- TODO: re-enable deletion once we know what should happen on routing deletion
            <base-button
              type="primary"
              class="btn-theme-EFEBEB table-action-button right-border"
              @click="showDeleteConfirmationModal(item.id)"
            >
              <img src="../../assets/img/trash.png" />
            </base-button>
                -->
          </div>
        </template>
      </v-data-table>
    </v-app>
    <confirmation-modal ref="confirmationModal" @delete="deleteRouting" />
  </div>
</template>

<script>
import ConfirmationModal from "@/views/common/ConfirmationModal.vue";
import SubcitiesDropdown from "@/views/common/SubcitiesDropdown.vue";
import { API, masterAPI } from "@/api/API";
export default {
  bodyClass: "landing",
  components: {
    ConfirmationModal,
    SubcitiesDropdown
  },
  data: function() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Parent City", value: "parentCity", sortable: false },
        { text: "Sub Cities", value: "subCities", sortable: false },
        {
          text: "Border Crossing Point",
          value: "crossBorderPoint",
          sortable: false
        },
        { text: "Description", value: "description", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ],
      targetRoutingId: ""
    };
  },
  props: {
    tableData: {
      type: Array
    },
    sectionName: {
      type: String
    }
  },
  methods: {
    /**
      @param {string} routingId The ID of the routing to be deleted
     */
    showDeleteConfirmationModal(routingId) {
      this.targetRoutingId = routingId;
      this.$refs.confirmationModal.showDeleteConfirmation();
    },
    async deleteRouting() {
      if (this.targetRoutingId) {
        const response = await masterAPI(
          API.API_ENDPOINT.routing,
          API.API_METHOD.delete,
          this.targetRoutingId
        );
        if (response.status == 200) {
          this.$toast.success("Routing deleted successfully");
          this.$emit("remove-item", this.sectionName);
        }
      }
    },
    editDuplicateView(routingId, isEdit) {
      const viewName = isEdit ? "edit" : "duplicate";
      this.$router.push({
        name: "editRouting",
        params: {
          view: viewName,
          id: routingId
        }
      });
    }
  }
};
</script>
