<template>
  <div class="wrapper tariff-app-container-height">
    <app-header v-if="!isCreate" :activeMenuName="activeMenuName" />
    <div :class="!isCreate ? 'col-md-7 container-right-border' : ''">
      <div :class="!isCreate ? 'custom-padding' : ''">
        <div v-if="!isCreate" class="mb-3">
          <span class="back-text-btn" @click="backView">
            Back
          </span>
        </div>
        <ValidationObserver v-slot="{ validate }" ref="formValidator">
          <form
            @submit.prevent="validate().then(saveAccessorial(false))"
            class="needs-validation"
            id="add-qo"
          >
            <div
              class="row mb-4"
              v-if="moduleView == sectionNameList.accessorial"
            >
              <div class="col-md-12 col-lg-5">
                <div class="create-section-text mb-2">
                  Select type of {{ moduleView }}
                </div>
                <ValidationProvider
                  rules="required"
                  :name="'Type of ' + moduleView"
                  v-slot="{ errors }"
                >
                  <choices-single
                    id="accessorialType"
                    :options="typeOfAccessorialList"
                    :value="selectedTypeOfAccessorial"
                    v-model="selectedTypeOfAccessorial"
                    @input="event => selectTypeOfAccessorialFsc(event)"
                  >
                    <option value="0">Select</option>
                  </choices-single>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div v-if="isCreate" class="create-section-text mb-2">
              Create a new field for this section
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-5">
                <base-input
                  type="text"
                  label="Name of field"
                  name="Name"
                  rules="required"
                  v-model="name"
                >
                </base-input>

                <div class="form-group has-label">
                  <label> Description of the section (optional) </label>
                  <textarea
                    label="Name of Section"
                    size="sm"
                    class="form-control"
                    rows="6"
                    v-model="description"
                  >
                  </textarea>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>

            <!-- Currency type dropdown starts-->
            <currency-selection
              @currency-default-changed="handleCurrencyDefaultChange"
            />
            <!-- Currency type dropdown ends -->

            <div v-if="moduleView == sectionNameList.fuelSurcharge">
              <eia-base-rates
                ref="eiaBaseRatesComponent"
                @update-use-eia-rate="updateUseEiaRate"
              />
            </div>

            <data-type-field-list
              :dataTypeFieldList="dataTypeFieldList"
              :dataTypeList="dataTypeList"
              @add-field="addField"
              @remove-field="removeField"
              :currencySymbol="currencySymbol"
              @update-items="setUploadedValues"
            />

            <min-max-charges-input
              ref="minMaxChargeInput"
              v-if="!shouldHideMinMax"
            />

            <button type="submit" class="btn mt-3 btn-default">
              Save
            </button>

            <div>
              <button
                type="button"
                class="btn mt-3 btn-default"
                @click="saveAccessorial(true)"
              >
                Save and Exit
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import ChoicesSingle from "@/components/SingleSelect";
import MinMaxChargesInput from "@/views/common/MinMaxChargesInput.vue";
import DataTypeFieldList from "@/views/common/DataTypeFieldList.vue";
import EiaBaseRates from "@/views/common/EiaBaseRates.vue";
import AppHeader from "@/layout/AppHeader.vue";
import CurrencySelection from "@/views/common/CurrencySelection.vue";

import {
  accountModuleApi,
  dataType,
  fuelSurchargeKey,
  getAccessorialKeyByName,
  getAccessorialNameByKey,
  getOptionDetailByName,
  getTypeItem,
  sectionList,
  getEiaBaseRatesList,
  getCurrencyType
} from "@/helpers/utility";
import { API, masterAPI } from "@/api/API";

export default {
  bodyClass: "landing",
  components: {
    ChoicesSingle,
    MinMaxChargesInput,
    DataTypeFieldList,
    EiaBaseRates,
    AppHeader,
    CurrencySelection
  },
  data: function() {
    return {
      activeMenuName: "create-custom-fields",
      // Data properties
      name: "",
      description: "",
      dataType: dataType,
      dataTypeList: [],
      dataTypeFieldList: [
        {
          type: "",
          item: [],
          pricingMethods: null
        }
      ],
      dbAccessorial: [],
      isDuplicate: false,
      moduleType: "",
      typeOfAccessorialList: [],
      selectedTypeOfAccessorial: "",
      sectionNameList: sectionList,
      alreadySavedAccessorialFsc: false,
      id: "",
      currencyDefault: "USD"
    };
  },
  props: {
    // Component props
    moduleView: {
      type: String
    },
    isCreate: {
      type: Boolean
    }
  },
  watch: {
    // Watchers for name and description properties
    name: {
      handler(value) {
        this.changeAccessorialFormHandler("name", value);
      },
      immediate: true
    },
    description: {
      handler(value) {
        this.changeAccessorialFormHandler("description", value);
      },
      immediate: true
    }
  },
  computed: {
    // Computed property for recordId
    recordId() {
      return +this.$route.params.id;
    },
    currentViewName() {
      return this.$route.params.view;
    },
    currencySymbol() {
      const currencyType = getCurrencyType(this.currencyDefault);
      return currencyType;
    },
    // Determines if the <min-max-charges-input> should be hidden
    shouldHideMinMax() {
      const hasLiftgate = this.selectedTypeOfAccessorial
        .toLowerCase()
        .includes("liftgate");

      const hasFixedPrice = this.dataTypeFieldList.some(
        field => field.type === "Fixed Price"
      );

      // Hide if either "Liftgate" is in the name or any field type is "Fixed Price"
      return hasLiftgate && hasFixedPrice;
    }
  },
  methods: {
    /**
     * Event handler for changing name and description fields.
     * @param {string} field - The field name being changed.
     * @param {string} value - The new value of the field.
     */
    changeAccessorialFormHandler(field, value) {
      this.isDuplicate = value != this.dbAccessorial[field];
    },
    /**
     * Event handler for changing currency type.
     */
    handleCurrencyDefaultChange(newValue) {
      this.currencyDefault = newValue;
    },
    /**
     * Saves the accessorial or fuel surcharge by either duplicating or updating it.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save or update.
     */
    saveAccessorial(isSaveExit) {
      if (this.isCreate) {
        this.isDuplicate = true;
        this.moduleType = this.moduleView;

        if (this.alreadySavedAccessorialFsc) {
          this.updateAccessorialFsc(isSaveExit);
        } else {
          this.createAccessorialFsc(isSaveExit);
        }
      } else {
        if (this.currentViewName == "duplicate") {
          this.createAccessorialFsc(isSaveExit);
        } else {
          this.updateAccessorialFsc(isSaveExit);
        }
      }
    },
    /**
     * Validates the min and max charges based on the accessorial type and pricing type.
     */
    checkMinMaxValidation() {
      // Check conditions to skip minMax validation
      const hasLiftgate = this.selectedTypeOfAccessorial
        .toLowerCase()
        .includes("liftgate");
      const hasFixedPrice = this.dataTypeFieldList.some(
        field => field.type === "Fixed Price"
      );

      // Return validation status
      if (hasLiftgate && hasFixedPrice) {
        return true; // Skip validation if conditions are met
      }

      // Perform validation if conditions are not met
      return this.$refs.minMaxChargeInput.checkMinMaxValueValidation();
    },

    /** Function to check if Liftgate and Fixed Price conditions are met
     *
     */
    checkLiftgateAndFixedPriceConditions() {
      const hasLiftgate = this.selectedTypeOfAccessorial
        .toLowerCase()
        .includes("liftgate");
      const hasFixedPrice = this.dataTypeFieldList.some(
        field => field.type === "Fixed Price"
      );

      return { hasLiftgate, hasFixedPrice };
    },
    /**
     * Create and Duplicates the accessorial or fuel surcharge.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save.
     */
    async createAccessorialFsc(isSaveExit) {
      // Perform form validation
      const isFormValid = await this.checkFormValidator();
      const minMaxValid = this.checkMinMaxValidation();

      if (isFormValid && minMaxValid) {
        if (this.isDuplicate) {
          let accessorialType = await this.getAccessorialType();
          const dataTypeFields = getTypeItem(this.dataTypeFieldList);

          let bodyPayload = {
            moduleType: this.moduleType,
            accessorialType: accessorialType,
            name: this.name,
            description: this.description,
            dataTypeFields: dataTypeFields
          };

          // Get Liftgate and Fixed Price conditions
          const {
            hasLiftgate,
            hasFixedPrice
          } = this.checkLiftgateAndFixedPriceConditions();

          // If the conditions are met (Liftgate and Fixed Price), skip adding minCharge and maxCharge
          if (!(hasLiftgate && hasFixedPrice)) {
            let minChargeValue = this.$refs.minMaxChargeInput.minValue;
            let maxChargeValue = this.$refs.minMaxChargeInput.maxValue;
            // Only add minCharge and maxCharge if the conditions are NOT met
            bodyPayload.minCharge = minChargeValue;
            bodyPayload.maxCharge = maxChargeValue;
          }

          // Handle special case for Fuel Surcharge API
          if (this.isFscApi()) {
            let useEiaRate = this.$refs.eiaBaseRatesComponent.useEiaRate;

            // If EIA rate is used, include it in the payload
            if (useEiaRate) {
              let baseEiaRate = this.$refs.eiaBaseRatesComponent.baseEiaRate
                .key;
              bodyPayload.useEiaRate = useEiaRate;
              bodyPayload.baseEiaRate = baseEiaRate;
            }
          }

          let response = await masterAPI(
            API.API_ENDPOINT.accessorial,
            API.API_METHOD.post,
            undefined,
            JSON.stringify(bodyPayload)
          );

          if (response.status == 200) {
            if (this.isCreate) {
              this.alreadySavedAccessorialFsc = true;
              this.id = response.data.id;

              if (this.isFscApi()) {
                this.$toast.success("Fuel Surcharge created successfully");
              } else {
                this.$toast.success("Accessorial created successfully");
              }
            } else {
              if (this.isFscApi()) {
                this.$toast.success("Fuel Surcharge duplicate successfully");
              } else {
                this.$toast.success("Accessorial duplicate successfully");
              }
            }

            if (isSaveExit) {
              this.backView();
            }
          } else {
            let error = response.data.message;
            this.$toast.error(error.replaceAll('"', ""));
          }
        } else {
          if (this.isFscApi()) {
            this.$toast.error(
              "Fuel Surcharge cannot be duplicated until a change as been made"
            );
          } else {
            this.$toast.error(
              "Accessorial cannot be duplicated until a change as been made"
            );
          }
        }
      }
    },
    /**
     * Asynchronously retrieves the accessorial type based on conditions.
     * If the application is using FSC API, it returns the fuel surcharge key.
     * Otherwise, it fetches the accessorial name based on the selected type of accessorial.
     * @returns {Promise<string|null>} - A promise that resolves to the accessorial type or null if not applicable.
     */
    async getAccessorialType() {
      if (this.isFscApi()) {
        return fuelSurchargeKey;
      } else {
        return await getAccessorialNameByKey(
          this.typeOfAccessorialList,
          this.selectedTypeOfAccessorial
        );
      }
    },
    /*
     * Set the input values of the fuel surcharges based on the uploaded csv values
     */
    setUploadedValues(values) {
      const importedValues = JSON.parse(JSON.stringify(values)); // Stripping Vue observer
      this.dataTypeFieldList[0].item = importedValues;
    },
    /**
     * Updates the accessorial or fuel surcharge.
     * Performs form validation and updates the record if valid.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the update.
     */
    async updateAccessorialFsc(isSaveExit) {
      const isFormValid = await this.checkFormValidator();
      const minMaxValid = this.checkMinMaxValidation();

      if (isFormValid && minMaxValid) {
        let accessorialType = await this.getAccessorialType();
        const dataTypeFields = getTypeItem(this.dataTypeFieldList);

        let bodyPayload = {
          accessorialType: accessorialType,
          name: this.name,
          description: this.description,
          dataTypeFields: dataTypeFields
        };

        // Get Liftgate and Fixed Price conditions
        const {
          hasLiftgate,
          hasFixedPrice
        } = this.checkLiftgateAndFixedPriceConditions();

        // If the conditions are met (Liftgate and Fixed Price), skip adding minCharge and maxCharge
        if (!(hasLiftgate && hasFixedPrice)) {
          let minChargeValue = this.$refs.minMaxChargeInput.minValue;
          let maxChargeValue = this.$refs.minMaxChargeInput.maxValue;
          // Only add minCharge and maxCharge if the conditions are NOT met
          bodyPayload.minCharge = minChargeValue;
          bodyPayload.maxCharge = maxChargeValue;
        }

        // Handle special case for Fuel Surcharge API
        if (this.isFscApi()) {
          let useEiaRate = this.$refs.eiaBaseRatesComponent.useEiaRate;

          // If EIA rate is used, include it in the payload
          if (useEiaRate) {
            let baseEiaRate = this.$refs.eiaBaseRatesComponent.baseEiaRate.key;
            bodyPayload.useEiaRate = useEiaRate;
            bodyPayload.baseEiaRate = baseEiaRate;
          } else {
            bodyPayload.useEiaRate = null;
            bodyPayload.baseEiaRate = null;
          }
        }

        let endPoint = this.isFscApi()
          ? API.API_ENDPOINT.fuelsurcharge
          : API.API_ENDPOINT.accessorial;

        let accessorialFscId = this.recordId ? this.recordId : this.id;

        let response = await masterAPI(
          endPoint,
          API.API_METHOD.put,
          accessorialFscId,
          JSON.stringify(bodyPayload)
        );

        if (response.status == 200) {
          if (this.isFscApi()) {
            this.$toast.success("Fuel Surcharge update successfully");
          } else {
            this.$toast.success("Accessorial update successfully");
          }

          if (isSaveExit) {
            this.backView();
          }
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ""));
        }
      }
    },

    /**
     * Navigates back to the previous view using the Vue Router.
     */
    backView() {
      this.$router.push({
        name: "viewallsection",
        params: { preselected: this.moduleView }
      });
    },
    /**
     * Retrieves the accessorial or fuel surcharge data.
     * Makes an API call to fetch the data based on the recordId.
     * Sets the retrieved data to the component properties.
     */
    async getAccessorialFsc() {
      if (this.recordId) {
        let endPoint = this.isFscApi()
          ? API.API_ENDPOINT.fuelsurcharge
          : API.API_ENDPOINT.accessorial;
        let response = await masterAPI(
          endPoint + "/" + API.API_ENDPOINT.single,
          API.API_METHOD.get,
          this.recordId,
          undefined
        );

        if (response.status == 200) {
          let accessorialFscData = response.data;
          this.setAccessorialFscResponse(accessorialFscData);
          this.dbAccessorial = accessorialFscData;
        }
      }
    },
    /**
     * Sets the accessorial or fuel surcharge response data to the component properties.
     * @param {object} accessorialFscData - The accessorial or fuel surcharge data.
     */
    async setAccessorialFscResponse(accessorialFscData) {
      this.moduleType = accessorialFscData.moduleType;
      this.name = accessorialFscData.name;
      this.description = accessorialFscData.description;
      if (!this.isFscApi()) {
        this.selectedTypeOfAccessorial = getAccessorialKeyByName(
          this.typeOfAccessorialList,
          accessorialFscData.accessorialType
        );
        setTimeout(() => {
          document.querySelector(
            "#accessorialType .choices__item"
          ).innerText = this.selectedTypeOfAccessorial;
        }, 30);
      }

      let priceMethods = accessorialFscData.dataTypeFields;

      let useEiaRate = accessorialFscData.useEiaRate;

      if (useEiaRate) {
        let baseEiaRate = accessorialFscData.baseEiaRate;
        this.$refs.eiaBaseRatesComponent.useEiaRate = useEiaRate;
        this.dataTypeList = await this.getFuelSurchargePricingMethods(
          useEiaRate
        );
        let eiaBaseRatesList = await getEiaBaseRatesList();

        let selectedOptionDetail = eiaBaseRatesList.filter(filterItem => {
          if (filterItem.key == baseEiaRate) {
            return filterItem;
          }
        });

        this.$refs.eiaBaseRatesComponent.baseEiaRate =
          selectedOptionDetail.length > 0 ? selectedOptionDetail[0] : null;
      } else {
        this.dataTypeList = await this.getFuelSurchargePricingMethods(false);
      }

      this.dataTypeFieldList = priceMethods.map((fieldItem, index) => {
        const priceMethodDetail = getOptionDetailByName(
          this.dataTypeList,
          fieldItem.type
        );

        setTimeout(() => {
          document.querySelector(
            `#data_type_${index} .choices__item`
          ).innerText = fieldItem.type;
        }, 30);

        return {
          type: fieldItem.type,
          item: fieldItem.item,
          pricingMethods: priceMethodDetail
        };
      });

      if (accessorialFscData.minCharge) {
        this.$refs.minMaxChargeInput.minValue = accessorialFscData.minCharge;
        this.$refs.minMaxChargeInput.showMinInput = true;
      }

      if (accessorialFscData.maxCharge) {
        this.$refs.minMaxChargeInput.maxValue = accessorialFscData.maxCharge;
        this.$refs.minMaxChargeInput.showMaxInput = true;
      }
    },
    /**
     * Removes a field from the data type field list.
     * If there is only one item in the list, displays an error toast.
     * @param {number} mainIndex - The main index of the dataTypeFieldList.
     * @param {number} subIndex - The sub-index of the item within the dataTypeFieldList.
     */
    removeField(mainIndex, subIndex) {
      let itemLen = this.dataTypeFieldList[mainIndex].item.length;
      if (itemLen > 1) {
        this.dataTypeFieldList[mainIndex].item.splice(subIndex, 1);
      } else {
        this.$toast.error("Atleast one item required");
      }
      this.isDuplicate = true;
    },
    /**
     * Adds a field to the data type field list at the specified main index.
     * Performs form validation before adding the field.
     * @param {number} mainIndex - The main index of the dataTypeFieldList.
     */
    async addField(mainIndex) {
      const isFormValid = await this.checkFormValidator();
      if (isFormValid) {
        let unitPriceObj = {};
        let allowedValues = this.dataTypeFieldList[mainIndex].pricingMethods
          .allowedValues;
        let fieldItemObj = allowedValues.reduce((obj, allowedValue) => {
          obj[allowedValue.key] = "";
          return obj;
        }, unitPriceObj);

        this.dataTypeFieldList[mainIndex].item.push(fieldItemObj);
        this.isDuplicate = true;
      }
    },
    /**
     * Checks if the current API is for fuel surcharge.
     * @returns {boolean} - True if the API is for fuel surcharge, false for accessorial.
     */
    isFscApi() {
      return this.moduleView == sectionList.fuelSurcharge ? true : false;
    },
    /**
     * Performs form validation using the formValidator component.
     * @returns {Promise<boolean>} - A promise that resolves to true if the form is valid, false otherwise.
     */
    async checkFormValidator() {
      return await this.$refs.formValidator.validate();
    },
    /**
     * Updates the selected type of accessorial or FSC and sets a flag for duplication.
     * @param {string} e - The selected type of accessorial or FSC.
     */
    selectTypeOfAccessorialFsc(e) {
      this.selectedTypeOfAccessorial = e;
      this.isDuplicate = true;
    },
    /**
     * Asynchronous function to retrieve available accessorials data.
     * If the current API is the Fuel Surcharge (FSC) API, fetches fuel surcharge pricing methods.
     * Otherwise, fetches available accessorials and accessorial pricing methods.
     */
    async getAvailableAccessorials() {
      if (this.isFscApi()) {
        if (!this.recordId) {
          this.dataTypeList = await accountModuleApi(
            API.API_ENDPOINT.fuelSurchargePricingMethods
          );
        }
      } else {
        this.typeOfAccessorialList = await accountModuleApi(
          API.API_ENDPOINT.availableAccessorials
        );
        this.dataTypeList = await accountModuleApi(
          API.API_ENDPOINT.accessorialPricingMethods
        );
      }

      this.getAccessorialFsc();
    },
    /**
     * Updates the useEiaRate property and fetches fuel surcharge pricing methods accordingly.
     * @param {boolean} useEiaRate - Indicates whether to use EIA rates.
     * @returns {Promise} A promise that resolves with the fuel surcharge pricing methods data.
     */
    async updateUseEiaRate(useEiaRate) {
      this.dataTypeList = await this.getFuelSurchargePricingMethods(useEiaRate);
    },
    /**
     * Retrieves fuel surcharge pricing methods based on the useEiaRate parameter.
     * @param {boolean} useEiaRate - Indicates whether to use EIA rates.
     * @returns {Promise} A promise that resolves with the fuel surcharge pricing methods data.
     */
    async getFuelSurchargePricingMethods(useEiaRate) {
      let apiEndPoint = API.API_ENDPOINT.fuelSurchargePricingMethods;

      if (useEiaRate) {
        apiEndPoint = `${API.API_ENDPOINT.fuelSurchargePricingMethods}?eia=${useEiaRate}`;
      }

      let response = await accountModuleApi(apiEndPoint);

      return response;
    }
  },
  created() {
    this.getAvailableAccessorials();
  }
};
</script>

<style></style>
