<template>
  <div class="tenant-select tariff-app-container-height">
    <div class="container">
      <div class="text-center mt-3">
        <h3 class="font-weight-bold text-primary">Select an Account</h3>
      </div>
      <div>
        <base-input
          type="text"
          placeholder="Search accounts..."
          v-model="searchInput"
        />
      </div>
      <div class="row">
        <div
          class="tenant-card-wrapper"
          v-for="account in filteredAccounts"
          :key="account.id"
          @click="() => handleChoice(account.id)"
        >
          <div class="tenant-card">
            {{ account.name }}
          </div>
        </div>
      </div>
      <div class="mt-5">
        <button
          type="button"
          class="btn btn-default w-100"
          @click="handleAccountCreate"
        >
          Add Account
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { API, masterAPI } from "../../api/API";
export default {
  components: {},
  data() {
    return {
      searchInput: "",
      accounts: []
    };
  },
  computed: {
    filteredAccounts() {
      if (this.searchInput === "") {
        return this.accounts;
      }
      return this.accounts.filter(account => {
        return account.name
          .toLowerCase()
          .includes(this.searchInput.toLowerCase());
      });
    }
  },
  async mounted() {
    const response = await masterAPI(
      API.API_ENDPOINT.getAccounts,
      API.API_METHOD.get
    );
    if (response.status >= 200 && response.status < 400) {
      this.accounts = [...response.data];
    }
  },
  methods: {
    async handleChoice(id) {
      const response = await masterAPI(
        API.API_ENDPOINT.switchAccount,
        API.API_METHOD.post,
        null,
        JSON.stringify({ accountId: id })
      );
      localStorage.setItem("accountId", id);
      if (response.status >= 200 && response.status < 400) {
        const account = this.accounts.find(account => account.id === id);
        await this.$store.dispatch("updateAccount", account);
        localStorage.setItem("id_token", response.data.id_token);
        await this.$store.dispatch("getProfileData");
        this.$router.push({
          name: "viewallsection"
        });
      }
    },
    handleAccountCreate() {
      this.$router.push({
        name: "newTenant"
      });
    }
  }
};
</script>
<style>
.tenant-select .row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 !important;
}
.tenant-card-wrapper {
  flex: 1 1 calc(50% - 5px);
  max-width: calc(50% - 5px);
}
@media (min-width: 848px) {
  .tenant-select .container {
    max-width: 848px;
  }
}
@media (max-width: 768px) {
  .tenant-card-wrapper {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
.tenant-card {
  animation-duration: 0.2s;
  border: 1px solid rgb(226, 232, 240);
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 16px;
}
.tenant-card:hover {
  border-color: #24876e;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
</style>
