<template>
  <div>
    <loader :isLoader="showLoader"></loader>
    <v-data-table
      v-model="selected"
      :headers="dynamicHeaders"
      :items="tableData"
      :single-select="singleSelect"
      :hide-default-footer="true"
      select-enable
      class="elevation-1"
      :items-per-page="tableData.length"
      :mobile-breakpoint="0"
    >
      <!-- Custom header slot for the "editing" column -->
      <template v-slot:header.editing v-if="isEditTariff">
        <editing-header-tariff
          :selectAll="selectAll"
          :tariffValue="headerTariffValue"
          :priceMode="priceMode"
          @update-select-all="handleSelectAll"
          @update-tariff-value="handleHeaderTariffValue"
          @update-header-price-mode="handleHeaderPriceMode"
          :uniqueId="uniqueId"
          :listName="listName"
          :tableData="tableData"
          :isDisableHeader="isDisableHeader"
        />
      </template>

      <!-- Slot for dynamically rendering editing column -->
      <template v-slot:item.editing="{ item }" v-if="isEditTariff">  
        <editing-column-tariff
          :item="item"
          :tariffValue="headerTariffValue"
          @row-selected="handleRowSelection"
          @update-price-mode="handleUpdatePriceMode"
          @update-tariff-value="handleUpdateTariffValue"
          :selectAll="selectAll"
          :listName="listName"
          :key="item.id"
        />
      </template>

      <template v-slot:item.dataTypeFields="{ item }">
        <div class="font-bold mb-1 mt-1">
          <a
            class="btn-link view-all-link-btn mb-2"
            @click="viewAllDataTypeFields(item)"
          >
            View all
          </a>
        </div>
      </template>
      <template v-slot:item.updatedAt="{ item }">
        <div class="mb-1 mt-1">
            {{ item.updatedAt }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div v-if="isTariff" @click="removeItem(item)" class="text-center">
          <i v-if="isParentView" class="fa fa-times" aria-hidden="true"></i>
        </div>
        <div v-else class="d-flex">
          <base-button
            type="primary"
            class="btn-theme-EFEBEB left-border btn-br1"
            @click="editDuplicateView(item.id, true)"
          >
            <img src="../../assets/img/edit.png"/>
          </base-button>
          <base-button
            type="primary"
            class="btn-theme-EFEBEB table-action-button btn-br1"
            @click="editDuplicateView(item.id, false)"
          >
            <img src="../../assets/img/duplicate.png"/>
          </base-button>
          <base-button
            type="primary"
            class="btn-theme-EFEBEB table-action-button right-border"
            @click="showDeleteConfirmationModal(item.id)"
          >
            <img src="../../assets/img/trash.png"/>
          </base-button>
        </div>
      </template>
    </v-data-table>

    <view-all-data-type-fields-modal
      ref="viewAllDataTypeFieldsModal"
      :selectedItem="selectedItem"
    />

    <!-- Modal component for displaying the selected updated date. -->
    <view-updated-date-modal
      ref="viewUpdatedAtModal"
      :selectedItem="selectedUpdatedAt"
    />

    <confirmation-modal
      ref="confirmationModal"
      @delete="deleteAccessorialFsc"
    />
  </div>
</template>

<script>
import { sectionList } from "@/helpers/utility";
import { API, masterAPI } from "@/api/API";
import ViewAllDataTypeFieldsModal from "@/views/common/ViewAllDataTypeFieldsModal.vue";
import Loader from "@/views/common/Loader.vue";
import ConfirmationModal from "@/views/common/ConfirmationModal.vue";
import ViewUpdatedDateModal from "@/views/common/ViewUpdatedDateModal.vue";
import EditingColumnTariff from "@/views/tariff/EditingColumnTariff.vue";
import EditingHeaderTariff from "@/views/tariff/EditingHeaderTariff.vue";

export default {
  bodyClass: "landing",
  components: {
    ViewAllDataTypeFieldsModal,
    Loader,
    ConfirmationModal,
    ViewUpdatedDateModal,
    EditingColumnTariff,
    EditingHeaderTariff,
  },
  data: function() {
    return {
      // Component's data properties
      singleSelect: false,
      selected: [],
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Data Type Fields", value: "dataTypeFields", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Updated On", value: "updatedAt", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ],
      selectedItem: {},
      selectedUpdatedAt: "",
      showLoader: false,
      accessorialFscId: null,
      headerTariffValue: null,
      localSelectAll: this.selectAll,
    };
  },
  props: {
    // Component props
    tableData: {
      type: Array
    },
    isTariff: {
      type: Boolean
    },
    isParentView: {
      type: Boolean
    },
    sectionName: {
      type: String
    },
    isEditTariff: {
      type: Boolean
    },
    selectAll: {
      type: Boolean
    },
    uniqueId: {
      type: String
    },
    listName: {
      type: String
    },
    priceMode: String,
    isDisableHeader: Boolean,
  },
  watch: {
    selectAll(newVal) {
      this.localSelectAll = newVal;
    },
  },
  /**
   * Dynamically generates the headers for the table based on the edit mode.
   */
  computed: {
    dynamicHeaders() {
      if (this.isEditTariff) {
        return [
          { text: "", value: "editing", sortable: false,  width: "222px" },
          ...this.headers,
        ];
      }
      return this.headers;
    },
  },
  methods: {
    /**
     * Remove an item from the table.
     * @param {Object} item - The item to be removed.
     */
    removeItem(item) {
      // Emit an event to notify parent component to remove the item
      this.$emit("remove-item", item);
    },
    /**
     * Navigates to the edit or duplicate view based on the provided parameters.
     * @param {string} laneId - The ID of the lane.
     * @param {boolean} isEdit - Indicates whether the view is for editing or duplicating.
     */
    editDuplicateView(laneId, isEdit) {
      let viewName = this.getViewName(isEdit);
      let screenName = "editFuelSurcharge";
      if (this.sectionName === sectionList.accessorial) {
        screenName = "editAccessorial";
      }
      this.$router.push({
        name: screenName,
        params: {
          view: viewName,
          id: laneId
        }
      });
    },
    /**
     * Gets the view name based on the edit mode.
     * @param {boolean} editMode - Indicates whether the view is for editing.
     * @returns {string} - The view name ('edit' or 'duplicate').
     */
    getViewName(editMode) {
      return editMode ? "edit" : "duplicate";
    },
    /**
     * Shows the delete confirmation modal for a selected record.
     * @param {string} selectedRecordId - The ID of the record to be deleted.
     */
    showDeleteConfirmationModal(selectedRecordId) {
      this.accessorialFscId = selectedRecordId;
      this.$refs.confirmationModal.showDeleteConfirmation();
    },
    /**
     * Asynchronously deletes an accessorial or FSC (Fuel Surcharge) by its ID.
     *
     */
    async deleteAccessorialFsc() {
      if (this.accessorialFscId) {
        this.showLoader = true;
        let response = await masterAPI(
          API.API_ENDPOINT.accessorial,
          API.API_METHOD.delete,
          this.accessorialFscId
        );

        this.showLoader = false;
        if (response.status == 200) {
          this.$toast.success(this.sectionName + " deleted successfully");
          this.$emit("remove-item", this.sectionName);
        }
      }
    },
    /**
     * Opens a modal to view all data type fields of a selected item.
     *
     * @param {Object} item - The selected item to view data type fields for.
     */
    viewAllDataTypeFields(item) {
      this.selectedItem = item;
      this.$refs.viewAllDataTypeFieldsModal.openModal();
    },
    /**
     * Opens a modal to view updated date of a selected item.
     *
     * @param {Object} item - The selected item to view updated date for.
     */
    viewUpdatedAt(item) {
      this.selectedUpdatedAt = item.updatedAt;
      this.$refs.viewUpdatedAtModal.openModal();
    },
    /**
     * Handles the selection of a row and logs the selected row ID.
     *
     * @param {string} selectedItem - The selected row.
     */
    handleRowSelection(selectedItem) {
      this.$emit('row-selected', selectedItem);
    },
    /**
     * Updates the price mode for a specific accessorial based on its ID.
     *
     * @param {string|number} accessorialId - The ID of the accessorial to update.
     * @param {string} newPriceMode - The new price mode to be applied.
     */
     handleUpdatePriceMode(accessorialId, newPriceMode) {
      this.$emit("update-price-mode", accessorialId, newPriceMode, this.listName);
    },
    /**
     * Emits the updated tariff value to the parent component.
     * @param {Object} payload - The payload containing the updated tariff value.
     */
    handleUpdateTariffValue(payload) {
      this.$emit('update-tariff-value', payload); // Re-emit the event to the parent
    },
    /**
     * Handles the "select all" action.
     * 
     * @param {boolean} value - Whether to check (true) or uncheck (false) all items.
     */
    handleSelectAll(value) {
      this.localSelectAll = value;
      this.$emit("update-select-all", value);
    },
    /**
     * Handles changes to the header tariff value and updates all rows accordingly.
     * 
     * @param {number} value - The new tariff value to set for all rows.
     */
    handleHeaderTariffValue(value) {
      this.headerTariffValue = value;

      // Update all row values when header input changes
      this.tableData.forEach((item) => {
        item.tariffValue = value;
      });
      this.$emit('update-tariff-value', { id: this.uniqueId, value });
    },
    /**
     * Handles changes to the priceMode
     * 
     * @param {string} newMode - The new price mode to set (either 'percentage' or 'dollar').
     */
    handleHeaderPriceMode(newMode) {
      this.$emit('update-header-price-mode', newMode);
    },
  },
  created() {}
};
</script>

<style></style>
