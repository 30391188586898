<template>
  <div>
    <routing-form :moduleView="moduleType" :isCreate="true" />
  </div>
</template>

<script>
import RoutingForm from "@/views/routing/RoutingForm.vue";
export default {
  bodyClass: "landing",
  components: {
    RoutingForm
  },
  props: {
    moduleType: {
      type: String
    }
  }
};
</script>
