<template>
  <div class="mb-1 mt-1">
    {{ subCitiesPreview }}
    <el-dropdown trigger="click" v-if="item.subCities.length > 3">
      <a class="btn-link view-all-link-btn mb-2">
        View all
      </a>
      <el-dropdown slot="title" />
      <el-dropdown-menu slot="dropdown">
        <div v-for="({ city }, i) in item.subCities" :key="i" class="cmb-2">
          {{ city }}
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";
  export default {
    name: "",
    components: {
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu
    },
    computed: {
      subCitiesPreview: function() {
        return this.item.subCities
          .slice(0, 3)
          .map(({ city }) => city)
          .join(", ");
      }
    },
    props: {
      item: {
        type: Object
      }
    }
  };
</script>
