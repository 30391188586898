<template>
  <div class="d-flex align-items-center">
    <!-- Checkbox -->
    <div class="custom-control custom-checkbox">
      <input
        :id="uniqueId"
        type="checkbox"
        v-model="localSelectAll"
        class="custom-control-input"
        @change="emitSelectAll"
        :class="{
          'fuelSurcharge-selectall': listName === 'Fuel Surcharge',
          [`${listName}-selectall`]: listName !== 'Fuel Surcharge'
        }"
        :disabled="isDisableHeader"
      />
      <label :for="uniqueId" class="custom-control-label"></label>
    </div>

    <!-- Input -->
    <div class="ml-2">
      <vue-input
        class="mb-0"
        alternative
        placeholder="Value"
        type="number" 
        v-model="localTariffValue"
        @input="emitTariffValue"
        :disabled="isDisableHeader"
      >
      </vue-input>
    </div>

    <!-- Switch for Pricing Mode -->
    <div class="d-flex justify-content-end mt-3 mt-md-1 mt-lg-0 ml-2">
      <div class="d-flex toggle-switch">
        <button
          type="button" 
          class="btn switch-slider px-2 pt-1 price-symbol-switch" 
          :class="{ 'btn-primary': localPriceMode === 'percentage' }"
          style="margin-right: -3px;"
          :disabled="isDisableHeader"
          @click="emitUpdatePriceMode('percentage')"
        >
          <span> % </span>
        </button>
        <button
          type="button" 
          class="btn switch-slider px-2 pt-1 price-symbol-switch"
          :class="{ 'btn-primary': localPriceMode === 'dollar' }" 
          :disabled="isDisableHeader"
          @click="emitUpdatePriceMode('dollar')"
        >
          <span> $ </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { 
  sectionList
} from "@/helpers/utility";

export default {
  props: {
    selectAll: {
      type: Boolean,
      required: true,
    },
    tariffValue: [Number, String],
    priceMode: {
      type: String,
    },
    uniqueId: {
      type: String,
      required: true, // Ensure each table passes a unique ID
    },
    listName: {
      type: String
    },
    tableData: {
      type: Array
    },
    isDisableHeader: Boolean
  },
  data() {
    return {
      localSelectAll: this.selectAll,
      localTariffValue: this.tariffValue,
      localPriceMode: this.priceMode, // Local copy of the prop
    };
  },
  watch: {
    selectAll(newVal) {
      this.localSelectAll = newVal;
    },
    tariffValue(newVal) {
      this.localTariffValue = newVal;
    },
    priceMode(newVal) {
      this.localPriceMode = newVal; // Sync local data when the prop updates
    },
  },
  methods: {
    /**
     * Updates the select-all state in the parent component.
     */
    emitSelectAll() {
      this.$emit("update-select-all", this.localSelectAll);
    },
    /**
     * Updates the tariff value in the parent component.
     */
    emitTariffValue() {
      this.$emit("update-tariff-value", this.localTariffValue);
    },
    /**
     * Emits the updated price mode to the parent component.
     * @param {string} mode - The new price mode.
     */
    emitUpdatePriceMode(mode) { 
      // Emit the updated price mode to the parent
      this.$emit("update-header-price-mode", mode, this.listName);

      // Conditionally update localPriceMode based on listName
      if (this.listName === sectionList.lane) {
        this.$nextTick(() => {
          this.localPriceMode = mode; // Sync with parent
        });
      }
    },
  },

  /**
   * This ensures that the parent component is updated with the initial price mode when the component is loaded.
   */
  mounted() {
    // this.emitUpdatePriceMode(this.localPriceMode);
  },
};
</script>