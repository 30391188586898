<template>
  <div class="wrapper tariff-app-container-height">
    <ValidationObserver v-slot="{ validate }" ref="formValidator">
      <form
        @submit.prevent="validate().then(saveRouting(false))"
        class="needs-validation"
        id="add-routing"
      >
        <div class="row">
          <div class="col-md-12 col-lg-5">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <base-input type="text" label="Name" name="Name" v-model="name">
              </base-input>
              <span v-if="errors.length" class="validate-error">
                Name is required
              </span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-12 col-lg-5 has-label">
            <label>Parent City</label>
            <ValidationProvider
              name="parentCity"
              rules="required"
              v-slot="{ errors }"
            >
              <vue-google-autocomplete
                classname="form-control mb-1"
                placeholder="City - Postal code"
                id="parent-city"
                v-model="parentCity"
                types="(regions)"
                rules="required"
                v-on:placechanged="setParentCity"
                :country="country"
              />
              <span v-if="errors.length" class="validate-error">
                The Parent City field is required
              </span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-lg-6">
            <label>Sub Cities</label>
            <div
              v-for="(item, index) in subCities"
              :key="index"
              class="row mb-2"
            >
              <div class="col-10 col-md-10 autocomplete-pr has-label">
                <vue-google-autocomplete
                  :ref="'sub_city_' + index"
                  classname="form-control mb-1"
                  placeholder="City - Postal code"
                  :id="'sub_city_' + index"
                  :name="'sub_city_' + index"
                  types="(regions)"
                  v-model="subCities[index]"
                  v-on:placechanged="
                    (_, placeData) => addSubCity(placeData, index)
                  "
                  :country="country"
                />
              </div>
              <div class="col-2 col-md-2">
                <i
                  class="fa fa-trash delete-icon"
                  @click="removeSubCity(index)"
                >
                </i>
              </div>
            </div>
            <div class="mb-3 mt-2">
              <a class="btn btn-link a-btn" @click="addAdditionalSubCity">
                Add sub-city +
              </a>
              <div v-if="subCities.length === 0" class="validate-error">
                Add at least one sub-city
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-md-12 col-lg-5 has-label">
            <label>Border Crossing Point (optional)</label>
            <vue-google-autocomplete
              classname="form-control mb-1"
              placeholder="City - Postal code"
              id="cross_border_point"
              v-model="crossBorderPoint"
              types="(regions)"
              v-on:change="setCrossBorderPoint"
              :country="country"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-lg-5">
            <div class="form-group has-label">
              <label> Description for this routing (optional) </label>
              <textarea
                label="Description"
                size="sm"
                class="form-control mb-4"
                rows="6"
                max-rows="6"
                v-model="description"
              >
              </textarea>
            </div>
          </div>
        </div>

        <button
          :disabled="isFormInvalid"
          type="submit"
          class="btn mt-3 btn-default btn-disable"
        >
          Save
        </button>

        <div>
          <button
            :disabled="isFormInvalid"
            type="button"
            class="btn mt-3 btn-default btn-disable"
            @click="saveRouting(true)"
          >
            Save and Exit
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { API, masterAPI } from "@/api/API";
import { sectionList } from "@/helpers/utility";
export default {
  bodyClass: "landing",
  components: {
    VueGoogleAutocomplete
  },
  data: function() {
    return {
      country: ["us", "ca"],
      parentCity: "",
      subCities: [],
      name: "",
      crossBorderPoint: "",
      description: "",
      isFormInvalid: false
    };
  },
  watch: {
    subcities: {
      handler() {
        this.checkFormValidity();
      },
      deep: true
    },
    parentCity() {
      this.checkFormValidity();
    },
    name() {
      this.checkFormValidity();
    }
  },
  props: {
    moduleView: {
      type: String
    },
    isCreate: {
      type: Boolean
    }
  },
  methods: {
    async checkFormValidity() {
      const isValid = await this.$refs.formValidator.validate();
      this.isFormInvalid = !isValid;
    },
    setParentCity(_, place) {
      this.parentCity = place.formatted_address;
    },
    addSubCity(place, index) {
      this.$set(this.subCities, index, place.formatted_address);
      this.checkFormValidity();
    },
    addAdditionalSubCity() {
      this.subCities.push("");
    },
    /**
     * Updates the values for the Google Autocomplete inputs of subCities
     */
    updateSubcitiesInputs() {
      this.$nextTick(() => {
        this.subCities.map((item, index) => {
          this.$refs[`sub_city_${index}`][0].update(item);
        });
      });
    },
    removeSubCity(index) {
      this.subCities.splice(index, 1);
      this.updateSubcitiesInputs();
    },
    setCrossBorderPoint(val) {
      this.crossBorderPoint = val;
    },
    async saveRouting(isSaveExit) {
      if (this.isFormInvalid) {
        return;
      }
      const formType = this.$route.params.view || "create";
      const methods = {
        edit: API.API_METHOD.put,
        duplicate: API.API_METHOD.post,
        create: API.API_METHOD.post
      };
      const id = this.$route.params.id;
      const bodyPayload = {
        parentCity: this.parentCity,
        subCities: this.subCities.map(city => ({ city })),
        crossBorderPoint: this.crossBorderPoint || null,
        name: this.name,
        description: this.description
      };
      const response = await masterAPI(
        API.API_ENDPOINT.routing,
        methods[formType],
        formType === "edit" ? id : null,
        JSON.stringify(bodyPayload)
      );
      if (response.status >= 200 && response.status < 400) {
        this.$toast.success("Routing saved successfully");
        if (isSaveExit) {
          this.$router.push({
            name: "viewallsection",
            params: { preselected: sectionList.routing }
          });
        }
      } else {
        this.$toast.error(response.data.message);
      }
    },
    /**
     * Fetches the existing routing if there is one.
     * @returns {Promise<boolean>} A promise that resolves to `true` if a routing is found (edit mode), or `false` otherwise.
     */
    async getRouting() {
      const id = this.$route.params.id;
      if (id) {
        const response = await masterAPI(
          API.API_ENDPOINT.routing,
          API.API_METHOD.get,
          id
        );
        if (response.status >= 200 && response.status < 400) {
          const { data } = response;
          this.parentCity = data.parentCity;
          this.name = data.name;
          this.description = data.description;
          this.crossBorderPoint = data.crossBorderPoint;
          this.subCities.splice(
            0,
            data.subCities.length,
            ...data.subCities.map(({ city }) => city)
          );
          this.updateSubcitiesInputs();
        } else {
          this.$toast.error(response.data.message);
        }
        return true;
      } else {
        return false;
      }
    }
  },
  beforeMount() {
    this.getRouting().then(isEdit => {
      if (isEdit) {
        this.checkFormValidity();
      }
    });
  }
};
</script>
