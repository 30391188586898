<template>
  <div>
    <app-header :activeMenuName="activeMenuName" />
    <div class="wrapper tariff-app-container-height">
      <div class="row tariff-app-container-height">
        <div class="col-md-7 container-right-border">
          <!-- Custom field view start-->
          <div v-if="showCustomFieldView" class="custom-padding">
            <div class="mb-3">
              <span class="back-text-btn" @click="backView">
                Back
              </span>
            </div>

            <div class="tarriff-title-text mb-4">
              Create a custom field
            </div>

            <div class="tarriff-sub-title-text mb-5">
              Build your tariff sheet section by section using custom fields
              that will allow you build pricing anyway that you want.
            </div>

            <div class="create-section-text mb-2">
              Choose the module(section) you want to this field to be created in
            </div>

            <div class="field-dropdown-main mb-4">
              <choices-single
                id="sectionListDropdown"
                :options="sectionFieldList"
                v-model="selectedField"
                @input="event => selectField(event)"
              >
                <option value="0">Select section</option>
              </choices-single>
            </div>

            <!-- Lane section component -->
            <div v-if="selectedField == sectionList.lane">
              <create-lane @save-module="resetModuleValue" />
            </div>

            <!-- Accessorial and Fuel Surcharge section component -->
            <div
              v-if="
                selectedField == sectionList.accessorial ||
                  selectedField == sectionList.fuelSurcharge
              "
            >
              <create-accessorial-fsc
                :moduleType="selectedField"
                @save-module="resetModuleValue"
              />
            </div>

            <!-- Conditions section component -->
            <div v-if="selectedField == sectionList.conditions">
              <create-condition
                :moduleType="selectedField"
                @save-module="resetModuleValue"
              />
            </div>

            <!-- Baserate section component  -->
            <div v-if="selectedField == sectionList.classBaseRate">
              <create-class-base-rate
                :moduleType="selectedField"
                @save-module="resetModuleValue"
              />
            </div>

            <div v-if="selectedField == sectionList.customerOrCustomerGroup">
              <create-customer @save-module="resetModuleValue" />
            </div>

            <div v-if="selectedField == sectionList.routing">
              <create-routing
                :moduleType="selectedField"
                @save-module="resetModuleValue"
              />
            </div>
          </div>
          <!-- Custom field view end -->

          <!-- Create section view start -->
          <div v-if="!showCustomFieldView" class="custom-padding-section">
            <div class="mb-3">
              <span class="back-text-btn" @click="createSection(true)">
                Back
              </span>
            </div>
            <div class="tarriff-title-text mb-4">
              Create your tariff
            </div>
            <div class="tarriff-sub-title-text mb-5">
              Build your tariff sheet section by section using custom fields
              that will allow you build pricing anyway that you want.
            </div>
            <div>
              <div class="create-section-text mb-2">
                Create a section for your tariff
              </div>
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    type="text"
                    label="Name of Section"
                    :class="invalidSectionName ? 'mb-0' : 'mb-3'"
                    v-model="sectionName"
                    @input="changeSectionName"
                  >
                  </base-input>
                  <div v-if="invalidSectionName" class="validate-error mb-1">
                    Name of section field is required
                  </div>
                  <div class="form-group has-label">
                    <label>
                      Description of the section (optional)
                    </label>
                    <textarea
                      label="Name of Section"
                      size="sm"
                      class="form-control mb-4"
                      rows="6"
                      v-model="sectionDescription"
                    >
                    </textarea>
                  </div>

                  <div>
                    <base-button
                      class="mb-3 btn-width"
                      @click="createNewSection(false)"
                    >
                      Save and add a new section
                    </base-button>
                  </div>

                  <div>
                    <base-button
                      class="btn-width"
                      @click="createNewSection(true)"
                    >
                      Save and Go Back
                    </base-button>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </div>
          <!-- Create section view end -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ChoicesSingle from "@/components/SingleSelect";
  import CreateLane from "@/views/lane/CreateLane.vue";
  import CreateAccessorialFsc from "@/views/accessorial/CreateAccessorialFsc.vue";
  import CreateCondition from "@/views/condition/CreateCondition.vue";
  import CreateCustomer from "@/views/customers/CreateCustomer.vue";
  import CreateClassBaseRate from "@/views/baserate/CreateClassBaseRate.vue";
  import CreateRouting from "@/views/routing/CreateRouting.vue";
  import { API, masterAPI } from "@/api/API";
  import { sectionList } from "@/helpers/utility";
  import AppHeader from "@/layout/AppHeader.vue";
  import { mapGetters } from "vuex";

  export default {
    components: {
      ChoicesSingle,
      CreateLane,
      CreateAccessorialFsc,
      CreateCondition,
      CreateCustomer,
      CreateRouting,
      AppHeader,
      CreateClassBaseRate
    },
    data: function() {
      return {
        activeMenuName: "create-custom-fields",
        showCustomFieldView: true,
        selectedField: null,
        preselected: "Lane",
        sectionList: sectionList,
        sectionFieldList: [],
        // Create section state
        sectionName: "",
        invalidSectionName: false,
        sectionDescription: ""
      };
    },
    computed: {
      ...mapGetters(["userIsBroker"])
    },
    methods: {
      /**
       * Reset the selectedField to null.
       */
      resetModuleValue() {
        this.selectedField = null;
      },
      /**
       * Update the sectionId when a field is selected.
       * @param {string} e - The selected field.
       */
      selectField(e) {
        this.selectedField = e;
        setTimeout(() => {
          document.querySelector(
            "#sectionListDropdown .choices__item"
          ).innerText = e;
        }, 30);
      },
      /**
       * Show or hide the custom field view.
       * @param {boolean} value - The value to set for showCustomFieldView.
       */
      createSection(value) {
        this.showCustomFieldView = value;
        localStorage.showCustomFieldView = value;
      },
      /**
       * Create a new section with the given sectionName and sectionDescription.
       * @param {boolean} screenUpdate - Indicates whether to update the screen after creating the section.
       */
      async createNewSection(screenUpdate) {
        if (this.sectionName == "") {
          this.invalidSectionName = true;
        } else {
          let bodyPayload = JSON.stringify({
            name: this.sectionName,
            description: this.sectionDescription
          });

          let response = await masterAPI(
            API.API_ENDPOINT.createSection,
            API.API_METHOD.post,
            undefined,
            bodyPayload
          );

          if (response.status == 200) {
            this.sectionId = response.data.id;
            this.sectionName = "";
            this.sectionDescription = "";
            this.createSection(screenUpdate);
            this.$toast.success("Section create successfully");
            this.getAllSection();
          } else {
            let error = response.data.message;
            this.$toast.error(error.replaceAll('"', ""));
          }
        }
      },
      /**
       * Check the validity of the section name and update the invalidSectionName flag accordingly.
       * @param {string} value - The section name to check.
       */
      changeSectionName(value) {
        if (value == "") {
          this.invalidSectionName = true;
        } else {
          this.invalidSectionName = false;
        }
      },
      /**
       * Get all sections from the API and populate the sectionFieldList.
       */
      async getAllSection() {
        this.sectionFieldList = [];

        let response = await masterAPI(
          API.API_ENDPOINT.availableSections,
          API.API_METHOD.get,
          undefined,
          undefined
        );

        if (response.status == 200) {
          let sectionField = response.data;
          if (this.userIsBroker) {
            sectionField = sectionField.filter(
              section => section.key !== "customerOrCustomerGroup"
            );
          }
          for (let i = 0; i < sectionField.length; i++) {
            let section = sectionField[i];
            let sectionObj = {
              id: i,
              text: section.name,
              key: section.key
            };
            this.sectionFieldList.push(sectionObj);
          }
        }
      },
      /**
       * Navigates back to the previous view using the Vue Router.
       */
      backView() {
        this.$router.push({
          name: "viewallsection",
          params: { preselected: this.selectedField }
        });
      }
    },
    async beforeMount() {
      if (this.$route.params.preselected) {
        this.preselected = this.$route.params.preselected;
        this.selectedField = this.$route.params.preselected;
      }
    },
    async created() {
      await this.getAllSection();
      if (!this.$route.params.preselected) {
        document.querySelector(
          "#sectionListDropdown .choices__item"
        ).innerText = "Lane";
        this.selectedField = "Lane";
      } else {
        this.selectedField = this.$route.params.preselected;
      }
    }
  };
</script>
<style></style>
